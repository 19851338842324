<template>
  <div>
    <h1>Clients</h1>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <div class="row">
      <div class="col-6">
        <!-- search input -->
        <div
          class="search-wrapper"
          style="margin-bottom: 16px;"
        >
          <h3>Search Company by Client ID</h3>
          <b-form-group
            label="This is the losing client"
            label-for="searchIDLoser"
          >
            <div class="d-flex align-items-left">
              <b-form-input
                id="searchIDLoser"
                v-model="searchIDLoser"
                placeholder="Search Company by Client ID"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <hr>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rowsLoser"
          :rtl="direction"
          :search-options="{
            enabled: false,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'Name'"
              class="text-nowrap width-30-per font-small-4"
            >
              <span
                v-if="props.row.BranchID === 0"
                class="text-nowrap"
              >{{ props.row.Name }}</span>
              <span
                v-else-if="props.row.BranchID !== 0"
                class="text-nowrap"
              >{{ "- " + props.row.Name }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'Status'">
              <b-badge :variant="statusVariant(props.row.CRMStatus)">
                {{ props.row.CRMStatus }}
              </b-badge>
            </span>

            <!-- Column: Id -->
            <span v-else-if="props.column.field === 'ID'">
              <span
                v-if="props.row.BranchID === 0"
                class="text-nowrap"
              >{{ props.row.ClientID + " (Client)" }}</span>
              <span
                v-else-if="props.row.BranchID !== 0"
                class="text-nowrap"
              >{{ props.row.BranchID + " (Branch)" }}</span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="props.row.BranchID === 0"
                    @click="setLosingClient(props.row)"
                  >
                    <feather-icon
                      icon="PaperclipIcon"
                      class="mr-50"
                    />
                    <span>
                      Merge Client
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.BranchID === 0"
                    @click="deleteClientWithPrompt(props.row)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete Client</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.BranchID !== 0"
                    @click="deleteBranchWithPrompt(props.row)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete Branch</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div
        v-if="clientToLose"
        class="col-6"
      >
        <!-- search input -->
        <div
          class="search-wrapper"
          style="margin-bottom: 16px;"
        >
          <h3>Search Company by Client ID</h3>
          <b-form-group
            label="Winning Client will gain losing user account’s resources"
            label-for="searchIDWinner"
          >
            <div class="d-flex align-items-left">
              <b-form-input
                id="searchIDWinner"
                v-model="searchIDWinner"
                placeholder="Search Company by Client ID"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <hr>

        <!-- table -->
        <vue-good-table
          :columns="columnsWin"
          :rows="rowsWinner"
          :rtl="direction"
          :search-options="{
            enabled: false,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'Name'"
              class="text-nowrap width-30-per font-small-4"
            >
              <span class="text-nowrap">{{ props.row.Name }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'Status'">
              <b-badge :variant="statusVariant(props.row.CRMStatus)">
                {{ props.row.CRMStatus }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>

        <div class="mt-1 float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click="mergeClientWithPrompt(rowsWinner[0])"
          >
            Merge Clients
          </b-button>
        </div>

      </div>
    </div>

    <b-modal
      id="modal-delete-client"
      ok-only
      ok-variant="danger"
      ok-title="Delete Client"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Client"
      @ok="confirmDeleteClient()"
    >
      <div v-if="clientToDelete !== null">
        Are you sure you want to delete client {{ clientToDelete.Name }}. This cannot be undone.
      </div>
    </b-modal>

    <b-modal
      id="modal-delete-branch"
      ok-only
      ok-variant="danger"
      ok-title="Delete Branch"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Branch"
      @ok="confirmDeleteBranch()"
    >
      <div v-if="branchToDelete !== null">
        You are about to delete {{ branchToDelete.Name }}. branch. This action cannot be undone.
      </div>
    </b-modal>

    <b-modal
      id="modal-delete-branch-with-requester"
      ok-variant="danger"
      ok-title="OK"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Branch"
      @ok="confirmDeleteBranch()"
    >
      <div v-if="branchToDelete !== null">
        Do you want to delete the requestors also?   If not, cancel this action and run Migrate Requestors first before delete.
      </div>
    </b-modal>

    <b-modal
      id="modal-merge-client"
      ok-only
      ok-variant="danger"
      ok-title="Merge Client"
      modal-class="modal-danger"
      centered
      title="Are you sure?"
      hide-backdrop
      size="md"
      @ok="confirmMergeClient()"
    >
      <template #modal-header="">
        <div
          class="
            d-flex
            mt-1
            mb-n3
            text-center
            flex-column
            justify-content-center
          "
        >
          <img
            class="alert-icon"
            src="../../assets/images/icons/alert.png"
          >
          <h1 class="text-center">
            <strong>Are you sure?</strong>
          </h1>
          <div v-if="clientToWin !== null">
            You are about to merge these clients.  This action cannot be undone.
          </div>
        </div>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(238,118,119,255)'"
              variant="outline-danger"
              size="md"
              @click="cancel()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(71,109,174,255)'"
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="ok()"
            >
              Merge
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BBadge,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";

const apiService = new APIService();


export default {
  components: {
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BModal,
    BButton,
  },
  data() {
    return {
      dir: false,
      awaitingSearch: false,
      clientToDelete: null,
      branchToDelete: null,
      clientToLose: null,
      clientToWin: null,
      columns: [
        {
          label: "Client",
          field: "Name",
        },
        {
          label: "ID",
          field: "ID",
        },
        {
          label: "Status",
          field: "CRMStatus",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      columnsWin: [
        {
          label: "Client",
          field: "Name",
        },
        {
          label: "Client ID",
          field: "ClientID",
        },
        {
          label: "Status",
          field: "CRMStatus",
        },
      ],
      rowsWinner: [],
      rowsLoser: [],
      searchIDWinner: "",
      searchIDLoser: "",
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
      breadcrumbItems: [
        {
          text: "Admin",
          href: '#'
        },
        {
          text: "Utility",
          href: '/admin/utility'
        },
        {
          text: "Clients/Insured",
          active: true,
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    searchIDWinner: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadClientWinner();
          this.awaitingSearch = false;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
    },
    searchIDLoser: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadClientLoser();
          this.awaitingSearch = false;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
    }
  },
  async mounted() {},
  async created() {},
  methods: {
    loadClientWinner() {
      this.rowsWinner = [];

      if (this.searchIDWinner.length || this.searchIDWinner.length) {
        // short circuit to search
        apiService
          .get(
            "client/" +
            encodeURIComponent(this.searchIDWinner) +
            "/merge/detail"
          )
          .then((response) => {
            this.rowsWinner = response.data.filter(client => client.BranchID === 0);
          });

        return;
      }
    },
    loadClientLoser() {
      this.rowsLoser = [];

      if (this.searchIDLoser.length || this.searchIDLoser.length) {
        // short circuit to search
        apiService
          .get(
            "client/" +
            encodeURIComponent(this.searchIDLoser) +
            "/merge/detail?isActiveBranches=false"
          )
          .then((response) => {
            this.rowsLoser = response.data.sort((a,b) => (a.CRMStatus > b.CRMStatus) ? 1 : ((b.CRMStatus > a.CRMStatus) ? -1 : 0));
          });
        return;
      }
    },
    setLosingClient(clientObj) {
      this.clientToLose = clientObj;
    },
    mergeClientWithPrompt(clientObj) {
      this.clientToWin = clientObj;
      this.$bvModal.show("modal-merge-client");
    },
    confirmMergeClient() {
      if (!this.clientToLose || !this.clientToWin) {
        return;
      }
      apiService
        .get("client/" + this.clientToWin.ClientID + "/merge/" + this.clientToLose.ID)
        .then((resp) => {
          if (resp) {
            this.clientToLose = null;
            this.clientToWin = null;
            this.rowsWinner = [];
            this.rowsLoser = [];
            this.searchIDLoser = this.searchIDWinner;
            this.searchIDWinner = "";
            this.showToast("success", "top-center", 4000, "Client merged");
            this.loadClientLoser();
          }
        });
      this.clientToDelete = null;
    },
    deleteClientWithPrompt(clientObj) {
      this.clientToDelete = clientObj;
      this.$bvModal.show("modal-delete-client");
    },
    deleteBranchWithPrompt(clientObj) {
      this.branchToDelete = clientObj;
      this.$bvModal.show(this.branchToDelete.IsRequestorExist ? "modal-delete-branch-with-requester" : "modal-delete-branch");
    },
    confirmDeleteClient() {
      if (!this.clientToDelete) {
        return;
      }
      apiService
        .delete("client/" + this.clientToDelete.ClientID)
        .then((resp) => {
          if (resp) {
            this.clientToLose = null;
            this.clientToWin = null;
            this.rowsWinner = [];
            this.rowsLoser = [];
            this.searchIDWinner = "";
            this.searchIDLoser = "";
            this.showToast("success", "top-center", 4000, "Client deleted");
          }
        });
      this.clientToDelete = null;
    },
    confirmDeleteBranch() {
      if (!this.branchToDelete) {
        return;
      }
      apiService
        .delete("client/branch/" + this.branchToDelete.BranchID)
        .then((resp) => {
          if (resp) {
            this.clientToLose = null;
            this.clientToWin = null;
            this.rowsWinner = [];
            this.rowsLoser = [];
            this.searchIDWinner = "";
            this.searchIDLoser = "";
            this.showToast("success", "top-center", 4000, "Branch deleted");
          }
        });
      this.branchToDelete = null;
    },
    showToast(variant, position, timeout, data) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "InfoIcon",
            text: data,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
};
</script>

<style>
/* overide classes just for this view better pattern than inline styles on bootstrap components aj */
[dir] .form-group {
  margin-bottom: 0;
}
.input-group-text {
  border-radius: 0;
}

[dir] .input-group > .form-control {
  height: 2.8em;
}
.alert-icon {
  width: 75px;
  margin: 30px auto 30px;
  display: block;
}
</style>
